import React from 'react';
import Content from "../Content";
import "./brand-elements.scss"

class BrandElements extends React.Component {

  render() {

    return (

      <article className="brand-elements">
        <Content>

          <div className="grid">
            <div className="col-12">
              <h2>How we make good work.</h2>
            </div>
              <div className="l_col-9 m_col-12 s_col-6">
                <a
                  href={`https://thumbprint.design/`}
                  target={`_blank`}
                >
                  <p className={`title heavy`}>Design system</p>
                </a>
              </div>
              <div className="m_col-6 s_col-12">
                <a
                  href={`https://thumbprint.design/`}
                  target={`_blank`}
                >
                  <p className={`desc`}>Thumbprint, our product design system, helps us build high-quality, consistent user experiences.</p>
                </a>
              </div>

              <div className="l_col-9 m_col-9 s_col-8">
                <a
                  href={`https://thumbtack.com/brand`}
                  target={`_blank`}
                >
                  <p className={`title heavy`}>Brand guidelines</p>
                </a>
              </div>
              <div className="m_col-6 s_col-12">

                <a
                  href={`https://thumbtack.com/brand`}
                  target={`_blank`}
                >
                  <p className={`desc`}>Our brand guidelines make sure we nail the Thumbtack brand in everything we create.</p>
                </a>
              </div>
          </div>

        </Content>
      </article>

    )
  }
}

export default BrandElements