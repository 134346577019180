import React from 'react';
import Content from "../Content";
import "./careers.scss"

const GREEHOUSE_THUMBTACK_URL = 'https://boards-api.greenhouse.io/v1/boards/thumbtack/jobs'
// const GREEHOUSE_AKQA_TEST_URL = 'https://boards-api.greenhouse.io/v1/boards/akqa/jobs'

class Careers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      jobs: []
    }
  }

  componentDidMount() {
    this.getJobs()
  }

  getJobs() {
    fetch(GREEHOUSE_THUMBTACK_URL)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then(json => {
        // console.log(json.jobs)
        this.setState({
          jobs: json.jobs,
          isLoaded: true
        });
      });
  }

  render() {

    const designTitles = [
      "design", "designer", "brand designer", "product designer", "product design manager",
      "research", "researcher", "experience researcher", "market researcher", "quantitative researcher", "research manager",
      "copywriting", "copywriter", "copywriting manager", " writing manager",
      "product writing", "product writer", " content strategist", " content strategy", " content strategy manager", " product writing manager",
      "research ops", "research coordinator", "research program manager",
      "design ops", "design program manager", "design coordinator",
      "production", "production designer",
      "creative producer", "producer",
      "videographer", "video editor",
      "design systems"
      // ,"Product Manager" // For testing
    ]

    let designCareers = this.state.jobs.filter(function (e) {
      let greenhouseTitle = e.title.replace(/\s+/g, '').toLowerCase();
      // console.log(`CAREERS designTitles ${designTitles.length}`)
      for(let i = 0; i < designTitles.length; i++ ) {
        let designTitle = designTitles[i].replace(/\s+/g, '').toLowerCase()
        // console.log( greenhouseTitle.includes(designTitle))
        if( greenhouseTitle.includes(designTitle) )
          return greenhouseTitle.includes(designTitle);
      }
    })

    return (

      <article id={`careers`} className="careers">
        <Content>

          <div className="grid">
            <div className="col-12">
              <h2>Where you fit in.</h2>
            </div>

            {designCareers.map((career, index) =>
              <div
                className="m_col-6 s_col-12"
                key={index}
              >
                <a
                  className={`job-cta`}
                  target={`_blank`}
                  href={career.absolute_url}
                >
                  <div
                    className={`posting`}
                  >
                    <h4 className={`easeInOutAll25`}>{career.title}</h4>
                    <p className={`small easeInOutAll25`}>{career.location.name}</p>
                  </div>
                </a>
              </div>
            )}

            <div className="col-12">
              <a
                className={`cta easeInOutAll25`}
                href={`https://www.thumbtack.com/careers`}
              >
                Learn more about working at Thumbtack <img className={`arrow`} src="https://ttc-design.s3-us-west-1.amazonaws.com/i/icon_arrow_large_2x.png" alt=""/>
              </a>
            </div>
          </div>

        </Content>
      </article>

    )
  }
}

export default Careers