import React from 'react';
import PropTypes from 'prop-types';
import Content from "../Content";
import "./we-are.scss"
import InstagramFeed from "../InstagramFeed";
import GatsbySourceInstagram from "../GatsbySourceInstagram"
import { gsap } from "gsap/dist/gsap";

// const TIME_FAST = .35;
const TIME_MEDIUM = .45;
// const TIME_SLOW = .55;
// const DELAY_FAST = .05;
const DELAY_MEDIUM = .1;
// const DELAY_SLOW = .15;
// const INTERVAL_FAST = 400;
const INTERVAL_MEDIUM = 500;
// const INTERVAL_SLOW = 600;

class WeAre extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isAnimating: false
    }
  }

  static propTypes = {
    interval: PropTypes.number,
  }

  componentDidMount() {
    this.animateCarousel()
  }

  animateCarousel() {
    // console.log(`WEARE animateCarousel`)
    const carousel = document.getElementById(`we-are-carousel`);
    const items = carousel.querySelectorAll(`.carousel-item`)
    // let carouselH = carousel.offsetHeight;
    // let heightIncrement = carouselH / items.length;
    // let top = carousel.style.top
    // let marginTop = carousel.style.marginTop
    // let boundingClientRect = carousel.getBoundingClientRect()
    // let offsetTop;
    // let offsetHeight;
    // let yPos;

    // console.log(`boundingClientRect top: ${boundingClientRect.top}, right: ${boundingClientRect.right}, bottom: ${boundingClientRect.bottom}, left: ${boundingClientRect.left}`);
    for(let i = 0; i < items.length; i++ ){
      let item = items[i]
      // let boundingClientRect = item.getBoundingClientRect()
      let offsetHeight = item.offsetHeight;
      let time = TIME_MEDIUM
      let delay = DELAY_MEDIUM
      // console.log(`item ${item.innerText} top: ${boundingClientRect.top}, offsetTop: ${item.offsetTop}, offsetHeight: ${item.offsetHeight}`);
      if(i === 0)
        gsap.to(item, time, {alpha: 0, ease: 'power1.inOut', onComplete: this.onAnimationComplete.bind(this)})
      else if(i === 1) {
        gsap.to(item, time, {y: -offsetHeight, ease: 'power1.inOut', delay: delay})
        gsap.to(item.querySelector(`P`), time, {css: {color: '#2F3033'}, ease: 'power1.inOut', delay: delay})
      }
      else {
        gsap.to(item, time, {y: -offsetHeight, ease: 'power1.inOut', delay: delay})
      }
    }
  }

  onAnimationComplete(e) {
    // console.log(`WEARE onAnimationComplete`)
    let to = setTimeout( () => {
      this.updateList()
      this.animateCarousel()
      clearInterval(to)
    }, INTERVAL_MEDIUM)
  }

  updateList() {
    // console.log(`WEARE updateList`)
    const carousel = document.getElementById(`we-are-carousel`);
    let items = carousel.querySelectorAll(`.carousel-item`)
    let firstItem = items[0]
    for(let i = 0; i < items.length; i++ ) {
      let item = items[i]
      gsap.to(item, 0, {y: 0, ease: 'power1.inOut'})
      gsap.to(item.querySelector(`P`), 0, {css: {color: '#d3d4d5'}, ease: 'power1.inOut'})
    }
    let parentNode = firstItem.parentNode;
    parentNode.removeChild(firstItem)
    parentNode.appendChild(firstItem)
    firstItem.style.opacity = 1;
  }


  render() {

    return (

      <article className="we-are">
        <Content>

          <div className="grid">
            <div className="col-12">
              <h2>We are</h2>

              <div className="carousel">

                <div id={`we-are-carousel`} className="carousel-items">
                  <div className="carousel-item">
                    <p className={`heavy`}>
                      designers.
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className={`heavy`}>
                      writers.
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className={`heavy`}>
                      researchers.
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className={`heavy`}>
                      producers.
                    </p>
                  </div>
                  <div className="carousel-item">
                    <p className={`heavy`}>
                      creatives.
                    </p>
                  </div>
                </div>
                <div id={`carousel-gradient`} className="gradient" />
              </div>
            </div>
          </div>

        </Content>

        {/*<GatsbySourceInstagram />*/}

        <InstagramFeed
          posts={[
            {
              small: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_designers_small_1_2x.jpg",
              medium: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_designers_medium_1_2x.jpg",
              large: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_1_2x.jpg",
              url: "https://www.instagram.com/thumbtack/",
            },
            {
              small: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_designers_small_2_2x.jpg",
              medium: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_designers_medium_2_2x.jpg",
              large: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_2_2x.jpg",
              url: "https://www.instagram.com/thumbtack/",
            },
            {
              small: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_designers_small_1_2x.jpg",
              medium: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_designers_medium_3_2x.jpg",
              large: "https://ttc-design.s3-us-west-1.amazonaws.com/i/we_are_3_2x.jpg",
              url: "https://www.instagram.com/thumbtack/",
              notMobile: "notMobile"
            }
          ]}/>

      </article>

    )
  }
}

WeAre.defaultProps = {
  interval: 250,
}

export default WeAre