import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import WeAre from "../components/WeAre"
import MediumFeed from "../components/MediumFeed"
// import Events from "../components/Events"
import Careers from "../components/Careers"
import '../../node_modules/@thumbtack/thumbprint-atomic';
import BrandElements from "../components/BrandElements";


const IndexPage = () => (

  <Layout>
    <SEO
      title={`Thumbtack Design`}
      description={`Bridging brand and product, we're a passionate team helping pros and customers find one another.`}
    />
    <section className="home">

      <Hero />

      <WeAre />

      <MediumFeed />

      <BrandElements />

      {/*<Events />*/}

      <Careers />

    </section>
  </Layout>
)

export default IndexPage
