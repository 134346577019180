import React from 'react';
// import ViewportFullbleed from "../ViewportFullbleed";
import ContentNoGutter from "../ContentNoGutter";
import Content from "../Content";
import "./hero.scss"
import {Button} from "@thumbtack/thumbprint-react";
import AutoVideoPlayer from "../AutoVideoPlayer";
import { gsap, ScrollToPlugin } from "gsap/all";

class Hero extends React.Component {

  onButtonClick(e) {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, 1, {scrollTo: "#careers"})
  }

  render() {

    return (

      <article className="segment hero">
        <Content>
          <AutoVideoPlayer
            srcL={`https://ttc-design.s3-us-west-1.amazonaws.com/v/TTC20121-Find-Your-Pros-1_08_1x1_650.mp4`}
            srcM={`https://ttc-design.s3-us-west-1.amazonaws.com/v/TTC20121-Find-Your-Pros-1_08_1x1_650.mp4`}
            srcS={`https://ttc-design.s3-us-west-1.amazonaws.com/v/TTC20121-Find-Your-Pros-1_08_1x1_650.mp4`}
            backupL={`https://ttc-design.s3-us-west-1.amazonaws.com/i/TTC20121-Find-Your-Pros-1_08_1x1_fallback_650.png`}
            backupM={`https://ttc-design.s3-us-west-1.amazonaws.com/i/TTC20121-Find-Your-Pros-1_08_1x1_fallback_650.png`}
            backupS={`https://ttc-design.s3-us-west-1.amazonaws.com/i/TTC20121-Find-Your-Pros-1_08_1x1_fallback_650.png`}
            classes={`border`}
            id={`hero-video`}
          />
        </Content>

        <ContentNoGutter>
          <Content>

            <div className="grid">
              <div className="col-12">
                <h1
                  id={`sticky-header-prepare-trigger`}
                >
                  Thumbtack <br />Design
                </h1>
              </div>

              <div className="l_col-6 m_col-12">
                <p className={`p-28`}>
                  Bridging brand and product, pixels and paragraphs, we’re a passionate team helping local pros and customers find one another.
                </p>
                <div id="sticky-header-trigger">
                  <Button
                    onClick={this.onButtonClick.bind(this)}
                  >
                    Join us
                  </Button>
                </div>
              </div>

              <div className="l_col-6 m_col-6 s_col-12">
              </div>
            </div>

          </Content>
        </ContentNoGutter>
      </article>

    )
  }
}

export default Hero