import React from "react"
import "./img-in-viewport.scss"

const ImgInViewport = props => (

  <div className={`img-in-viewport ${props.classes}`}>
    <picture>
      <source
        media="(max-width:700px)"
        srcSet={props.small}
      />
      <source
        media="(min-width:701px) and (max-width:1025px)"
        srcSet={props.medium}
      />
      <source
        media="(min-width:1026px) and (max-width:1440px)"
        srcSet={props.large}
      />
      <img
        src={props.large}
        alt="" />
    </picture>
  </div>
)

ImgInViewport.defaultProps = {
  classes: 'classes',
  small: "small",
  medium: "medium",
  large: "large",
  alt: "alt",
}

export default ImgInViewport