import React from 'react';
// import PropTypes from 'prop-types';
import Content from "../Content";
import "./medium-feed.scss"
import ImgInViewport from "../ImgInViewport";
// import Feed from "rss-to-json"

const THUMBTACK_DESIGN_MEDIUM_FEED = "https://medium.com/feed/thumbtack-design";
// const THUMBTACK_DESIGN_MEDIUM_FEED = "https://medium.com/feed/@jasontighe";

class MediumFeed extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: []
    }
  }

  // static propTypes = {
  //   posts: PropTypes.array,
  // }

  componentDidMount() {
    this.getFeed();
  }

  getFeed() {
      // console.log(`MEDIUMFEED getFeed`);
    // Feed.load(THUMBTACK_DESIGN_MEDIUM_FEED, function(err, rss){
    //   console.log(`MEDIUMFEED: rss: ${rss}`);
    // });
    fetch(`https://api.rss2json.com/v1/api.json?rss_url=${THUMBTACK_DESIGN_MEDIUM_FEED}`)
      .then((res) => res.json())
      .then((data) => {
        // Fillter the array
        const res = data.items //This is an array with the content. No feed, no info about author etc..
        const items = res.filter(item => item.categories.length > 0) // That's the main trick* !
        if(items.length > 4) items.length = 4;

        // for (let i = 0; i < items.length; i++) {
        //   console.log(`MEDIUMFEED: data.items: ${items[i].thumbnail}`);
        //   // let img = await this.getMeta("http://shijitht.files.wordpress.com/2010/08/github.png");
        //   this.getImageDimensions(items[i].thumbnail)
        //   let w = img.naturalWidth;
        //   let h = img.naturalHeight;
        //   console.log(`MEDIUMFEED getFeed w: ${w}, h: ${h}`);
        // }

        // console.log(`MEDIUMFEED: data.items: ${items}`);
        // for (const [key, value] of Object.entries(items[0])) {
        //   console.log(`${key}: ${value}`); // "a 5", "b 7", "c 9"
        // }

        this.setState({
          items: items,
          isLoaded: true
        });
      });


      //   fetch(THUMBTACK_DESIGN_MEDIUM_FEED)
      // .then(res => {
      //   if (res.ok) {
      //     return res.json();
      //   } else {
      //     throw Error(res.statusText);
      //   }
      // })
      // .then(json => {
      //   console.log(json)
      //   this.setState({
      //     items: json,
      //     isLoaded: true
      //   });
      // });
  }

  removeHTMLTags(s) {
    let regex = /(<([^>]+)>)/ig;
    return s.replace(regex, ``)
  }

  getImageDimensions(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  shorten(str, maxLen, separator = ' ') {
    if (str.length <= maxLen) return str;
    let s = str.substr(0, str.lastIndexOf(separator, maxLen));
    // Don't add dot truncation at end of sentences
    if(s.substr(-1) !== ".")
    {
      s += "..."
    }
    return s;
  }

  render() {

    // const posts = this.props.posts;
    const mediumPosts = this.state.items.map((item, index) =>

      <div
        key={index}
        className="m_col-6 s_col-12"
      >
        <div className={`medium-item ${item.last ? 'last' : '' }`}>
          <a
            className={`post`}
            href={item.link}
            target={`_blank`}
          >
            <div className="ratio-container ba b-gray-300 bw-2">
              <ImgInViewport
                classes={`banner ${'jason'}`}
                small={item.thumbnail}
                medium={item.thumbnail}
                large={item.thumbnail}
                alt={``}
              />
            </div>
            <div className="info">
              <h3
                className={`easeInOutAll25`}
              >{item.title}</h3>
              <p
                className={`p-20-25 easeInOutAll25`}
              >
                {this.shorten(String(this.removeHTMLTags(item.description)), 120)}
                {/*{String(this.removeHTMLTags(item.description)).substr(0, 120)+`...`}*/}
              </p>
              <p
                className={`small author easeInOutAll25`}
              >
                {item.author}
              </p>
            </div>
          </a>
        </div>
      </div>
    );


    return (

      <article className="medium-feed">
        <Content>

          <div className="grid">
            <div className="col-12">
              <h2>What we're thinking.</h2>
            </div>

            { mediumPosts }

            <div className="m_col-6 s_col-12">
              <a
                className={`cta`}
                href={`https://medium.com/thumbtack-design`}
              >
                <img
                  className={`icon`}
                  width="24"
                  src="https://ttc-design.s3-us-west-1.amazonaws.com/i/icon_medium_large_2x.png"
                  alt=""
                />
                More on Medium
              </a>
            </div>
          </div>

        </Content>
      </article>

    )
  }
}

// MediumFeed.defaultProps = {
//   posts: [],
// }

export default MediumFeed