import React from 'react';
import PropTypes from 'prop-types';
import ContentNoGutter from "../ContentNoGutter";
import Content from "../Content";
import ImgInViewport from "../ImgInViewport"
import "./instagram-feed.scss"


class InstagramFeed extends React.Component {

  static propTypes = {
    posts: PropTypes.array,
  }

  render() {

    const posts = this.props.posts;
    const instagramPosts = posts.map((post, index) =>
      <div
        key={index}
        className={`m_col-4 s_col-6 ${post.notMobile ? 'not-mobile' : '' }`}>
        <a
          href={post.url}
          target={`_blank`}
        >
          <ImgInViewport
            classes={`photo`}
            small={post.small}
            medium={post.medium}
            large={post.large}
            alt={``}
          />
        </a>
        <div className="insta" />
      </div>
    );


    return (

      <div className="instagram-feed">
        <ContentNoGutter>
          <div className="grid grid-insta">

            {instagramPosts}

          </div>
        </ContentNoGutter>

        <Content>
          <div className="grid">
            <div className="col-12">
              <a
                className={`cta`}
                href="https://www.instagram.com/thumbtack"
                target={`_blank`}
              >
                <img
                  className={`instagram-icon easeInOutAll25`}
                  width="24"
                  src="https://ttc-design.s3-us-west-1.amazonaws.com/i/icon_instagram_large_2x.png"
                  alt=""/>
                  Follow us on Instagram
              </a>
            </div>
          </div>
        </Content>

      </div>

    )
  }
}

InstagramFeed.defaultProps = {
  posts: 'posts',
}

export default InstagramFeed